// updateLanguage.js
'use strict';
import { MERGE_OPERATION_DATA } from '../ActionTypes.js';
import removeShopCurrency from '../action/removeShopCurrency.js';
import { replace } from '../action/navigationAction.js';
import i18n, { localeConvertor } from '../resource/i18n.js';
import { setRemoteMeta } from '../resource/mixpanel.js';
import { actionLog } from '../resource/debug.js';
import { getSupportedLanguage } from '../resource/getCurrentLanguage.js';
import getMeData from '../selector/getMeData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getOperationData from '../selector/getOperationData.js';
import getRouterData from '../selector/getRouterData.js';
import { SUPPORTED_LANGUAGES } from '../RemoteConfigKeys.js';
import * as MessageTypes from '../serviceWorker/AppMessageTypes.js';
import { sendMessageToSW } from '../serviceWorker/helpers.js';

const log = actionLog.extend('updateLanguage');

/**
 * Update language (both i18n.language and redux)
 * @kind action
 * @param {string} {language} - action param.
 * @return {Promise} Action promise.
 */
const updateLanguage =
  ({ language }) =>
  async (dispatch, getState) => {
    const lang = getSupportedLanguage({
      language,
      supportedLanguages: getRemoteConfigData(getState(), SUPPORTED_LANGUAGES),
    });

    i18n.changeLanguage(lang, error => {
      if (error) {
        return;
      }
      setRemoteMeta({ data: {} });
      const {
        pathname,
        search,
        hash,
        state: locationState,
      } = getRouterData(getState(), ['location']) || {};

      log('i18n.changeLanguage()', { search, lang });
      const searchParams = new URLSearchParams(search);
      const searchLang = searchParams.get('lang');
      if (searchLang !== lang) {
        // This replace() somehow causing NextJS plus `<suspense />` fail to hydrate,
        // which means all `<ClientContainer />` won't be mount.
        // So we add an if to skip replace when lang not changed,
        // which mostly only happens on init.
        log('i18n.changeLanguage()', { searchLang, lang });
        dispatch(
          replace(
            {
              pathname,
              search,
              hash,
            },
            locationState
          )
        );
      }
    });

    const usingShopId = getOperationData(getState(), ['shop'], 'usingShopId');
    // Don't remove currency if have `usingShopId`.
    // Remove currency break product detial UI, since there are some value need currency.
    if (!usingShopId) {
      dispatch(removeShopCurrency());
    }

    sendMessageToSW({
      type: MessageTypes.SET_AUTHORIZER_LANGUAGE,
      payload: {
        language: localeConvertor({ locale: language, isISO639: true }),
      },
    });
    // re-subscribe presence-enc-client@{CLIENT_ID}
    const clientId = getMeData(getState(), 'clientId');
    sendMessageToSW({
      type: MessageTypes.SUBSCRIBE_PRESENCE_CLIENT_CHANNEL,
      payload: {
        clientId,
      },
    });
    // re-subscribe presence-enc-user@{USER_ID}
    const meId = getMeData(getState(), 'id');
    sendMessageToSW({
      type: MessageTypes.SUBSCRIBE_PRESENCE_USER_CHANNEL,
      payload: {
        userId: meId,
      },
    });
    return dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['config'],
        data: {
          language: lang,
        },
      },
    });
  };

export default updateLanguage;
