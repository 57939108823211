// removeShopCurrency.js
'use strict';
import { SET_OPERATION_DATA } from '../ActionTypes.js';

/**
 * Remove shop currency
 * @kind action
 * @return {Promise} Action promise.
 */
const removeShopCurrency = () => async dispatch => {
  return dispatch({
    type: SET_OPERATION_DATA,
    payload: {
      selectPath: ['shop', 'currency'],
      data: null,
    },
  });
};

export default removeShopCurrency;
