// HydrationBoundary.jsx
// https://github.com/GoogleChromeLabs/progressive-rendering-frameworks-samples/blob/master/react-progressive-hydration/app/components/hydrator.js
import React from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';

import ErrorPage from '../page/ErrorPage.jsx';

const isServer = typeof window === 'undefined';
const context = {};

export const saveContext = ({ key, value }) => {
  context[key] = value;
  return;
};

export class HydrationBoundary extends React.PureComponent {
  hasHydrated = false;
  wrapperRef = React.createRef();

  hydrateChildren = async () => {
    const { children } = this.props;
    this.hasHydrated = true;

    const [
      { Provider },
      { Router },
      { default: ErrorBoundary },
      { hydrateRoot },
    ] = await Promise.all([
      import('../partial/react-redux/Provider.js'),
      import('../partial/redux-first-history/Router.js'),
      import('../component/ErrorBoundary.jsx'),
      import('../partial/react-dom/hydrateRoot.js'),
    ]);

    if (this.wrapperRef.current)
      return hydrateRoot(
        this.wrapperRef.current,
        <Provider store={context['store']}>
          <Router history={context['history']}>
            <I18nextProvider i18n={context['i18n']}>
              <ErrorBoundary
                onErrorResolved={context['handleErrorResolved']}
                errorElement={<ErrorPage />}
              >
                {children}
              </ErrorBoundary>
            </I18nextProvider>
          </Router>
        </Provider>
      );
  };

  componentDidMount() {
    const { shouldHydrate } = this.props;
    if (shouldHydrate) {
      this.hydrateChildren();
    }
  }

  componentDidUpdate({ shouldHydrate: prevShouldHydrate }) {
    const { shouldHydrate } = this.props;
    if (
      shouldHydrate !== prevShouldHydrate &&
      shouldHydrate &&
      !this.hasHydrated
    ) {
      this.hydrateChildren();
    }
  }

  render() {
    const { wrapper, children } = this.props;
    if (isServer) {
      return React.cloneElement(wrapper, null, children);
    }
    return React.cloneElement(wrapper, {
      ref: this.wrapperRef,
      dangerouslySetInnerHTML: { __html: '' },
      suppressHydrationWarning: true,
    });
  }
}

HydrationBoundary.propTypes = {
  wrapper: PropTypes.node,
  children: PropTypes.node,
  shouldHydrate: PropTypes.bool,
};

HydrationBoundary.defaultProps = {
  wrapper: <div />,
  children: '',
  shouldHydrate: false,
};

export default HydrationBoundary;
