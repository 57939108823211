// resumeUploadJob.js
'use strict';
import getListData from '../selector/getListData.js';
import getOperationData from '../selector/getOperationData.js';

import processUploadJob from '../action/processUploadJob.js';
import subscribePresenceMessage from '../action/subscribePresenceMessage.js';
import setUploadJobStatus from '../action/setUploadJobStatus.js';

import {
  Status as UploadStatus,
  HookStatus,
} from '../resource/uploadConstants.js';
import { upload as uploadDebug } from '../resource/debug.js';

const uploadLog = uploadDebug.extend('log:action/resumeUploadJob.js');

/**
 * Resume upload queue
 * @kind action
 * @return {Promise} Action promise.
 */
const resumeUploadJob = () => async (dispatch, getState) => {
  uploadLog('execute resumeUploadJob', {
    uploadJobIds: getListData(getState(), ['uploadJob'], 'itemIds'),
    uploadJob: getOperationData(getState(), ['uploadJob']),
  });
  const uploadJobIds = getListData(getState(), ['uploadJob'], 'itemIds') || [];
  const toFailedTasks = uploadJobIds
    .filter(uploadJobId => {
      const isUploadCompletedHookExecuted =
        HookStatus.COMPLETED ===
        getOperationData(
          getState(),
          ['uploadJob', uploadJobId, 'hook'],
          UploadStatus.UPLOAD_COMPLETED
        );
      const isFailed = [
        UploadStatus.FAILED,
        UploadStatus.UPLOAD_FAILED,
        UploadStatus.PROCESSING_FAILED,
        UploadStatus.REVIEW_FAILED,
        UploadStatus.DELIVERY_FAILED,
      ].includes(
        getOperationData(getState(), ['uploadJob', uploadJobId], 'status')
      );
      return !isUploadCompletedHookExecuted && !isFailed;
    })
    .map(uploadJobId =>
      dispatch(
        setUploadJobStatus({
          uploadJobId,
          status: UploadStatus.FAILED,
          failedReason: 'uploader_uploading_refresh_page_error',
        })
      )
    );
  await Promise.all(toFailedTasks);
  const toSubscribeTasks = uploadJobIds
    .filter(uploadJobId => {
      const isUploadCompletedHookExecuted =
        HookStatus.COMPLETED ===
        getOperationData(
          getState(),
          ['uploadJob', uploadJobId, 'hook'],
          UploadStatus.UPLOAD_COMPLETED
        );
      const isFailed = [
        UploadStatus.FAILED,
        UploadStatus.UPLOAD_FAILED,
        UploadStatus.PROCESSING_FAILED,
      ].includes(
        getOperationData(getState(), ['uploadJob', uploadJobId], 'status')
      );
      return isUploadCompletedHookExecuted && !isFailed;
    })
    .map(uploadJobId => {
      const messageId = getOperationData(
        getState(),
        ['uploadJob', uploadJobId],
        'messageId'
      );
      if (messageId) {
        return dispatch(subscribePresenceMessage({ messageId, uploadJobId }));
      }
    });
  await Promise.all(toSubscribeTasks);
  dispatch(processUploadJob());
  return uploadLog('finish resumeUploadJob');
};

export default resumeUploadJob;
