// ScrollMemory.js
'use strict';
import { connect } from 'react-redux';

import { SCROLL_MEMORY_DISABLED_LIST } from '../RemoteConfigKeys.js';
import ScrollMemory from '../component/ScrollMemory.jsx';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';

const mapStateToProps = state => {
  return {
    /**
     * A list of URL patterns that should disable scroll memory.
     *
     * @type {string[]}
     * @example
     * ['/u/:username', '/user/:id']
     */
    disabledList: getRemoteConfigData(state, SCROLL_MEMORY_DISABLED_LIST),
  };
};

export default connect(mapStateToProps)(ScrollMemory);
