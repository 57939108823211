import React, { useContext, useMemo } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import StaticContext from '../../../server/resource/staticContext.js';

function withRouter(Component) {
  return React.memo(function ComponentWithRouter(props) {
    const location = useLocation();
    const params = useParams();
    const match = useMemo(() => ({ params }), [params]);
    const navigate = useNavigate();
    const staticContext = useContext(StaticContext);
    return (
      <Component
        location={location}
        match={match}
        navigate={navigate}
        staticContext={staticContext}
        {...props}
      />
    );
  });
}

export default withRouter;
