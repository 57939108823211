// setIntercomData.js
'use strict';
import { MERGE_OPERATION_DATA } from '../ActionTypes.js';

const selectPath = ['intercom'];

/**
 * Set intercom unread count
 * @kind action
 * @param {bool} [{canDisplayIntercom}] - is intercom loaded.
 * @param {number} [{unreadCount}] - unread count.
 * @return {Promise} Action promise.
 */
const setIntercomData =
  ({ canDisplayIntercom, unreadCount }) =>
  async dispatch => {
    const data = {};

    if (undefined !== canDisplayIntercom) {
      data.canDisplayIntercom = canDisplayIntercom;
    }

    if (unreadCount || 0 === unreadCount) {
      data.unreadCount = Math.floor(unreadCount) || 0;
    }

    return dispatch({
      type: MERGE_OPERATION_DATA,
      payload: { selectPath, data },
    });
  };

export default setIntercomData;
